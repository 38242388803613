
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  components: {
    PDEIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validate needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'name')

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      errorMessage,
      handleChange,
      meta,
      value,
    } = useField(name, undefined, {
      initialValue: props.value,
    })

    return {
      errorMessage,
      handleChange,
      meta,
      value,
    }
  },

  computed: {
    placeholderText() {
      return this.isLoading ? 'Loading, please wait...' : this.placeholder
    },
    hasError() {
      return !!(this.errorMessage && !this.meta.valid)
    },
    selectClasses() {
      const classes = []

      classes.push(this.hasError ? 'border-error-900' : 'border-pureblack-20')

      if (!this.meta.dirty) classes.push('italic text-pureblack-60')

      return classes
    },
  },

  methods: {
    setOptionValue(option, key) {
      if (typeof option === 'string') {
        return option
      }
      return option[key]
    },
  },
}
